* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  /* font-family: "Montserrat", sans-serif; */
}

:root {
  --primary-100: #5928dc;
  --primary-80: rgba(88, 40, 220, 0.8);
  --primary-10: rgba(89, 40, 220, 0.1);
  --primary-6: rgba(88, 40, 220, 0.06);
  --primary-5: rgba(88, 40, 220, 0.05);
  --primary-0: rgba(88, 40, 220, 0);

  --secondary-100: #ffd8d9;
  --secondary-20: rgba(255, 216, 217, 0.2);

  --gray: #d9d9d9;
  --danger: #e02323;
  --success: #00f351;
  --white: #fcfcfc;
  --white-30: rgba(252, 252, 252, 0.3);

  --bg-primary-70: rgba(232, 233, 233, 0.7);
  --bg-primary: #f3f2f8;
  --bg-secondary: #d1dee1;

  --dark-100: #202021;
  --dark-85: rgba(32, 32, 33, 0.85);
  --dark-70: rgba(32, 32, 33, 0.7);
  --dark-50: rgba(32, 32, 33, 0.5);
  --dark-5: rgba(32, 32, 33, 0.05);

  --black-20: rgba(0, 0, 0, 0.2);
  --black-10: rgba(0, 0, 0, 0.1);

  --container-width: 1420px;
  --wide-container-width: 1780px;

  /* --rem3: 3rem;
    --rem1: 1rem;
    --px900: 900px;
    --px800: 800px;
    --px130: 130px;
    --px60: 60px;
    --px50: 50px;
    --px40: 40px;
    --px35: 35px;
    --px32: 32px;
    --px30: 30px;
    --px25: 25px;
    --px20: 20px;
    --px10: 10px; */
}

/* ----------------------------- Basic Setup ----------------------------- */
a {
  text-decoration: none !important;
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0 !important;
  padding: 0 !important;
}

ol,
ul {
  list-style: none;
  padding: 0 !important;
}

html {
  scroll-behavior: smooth;
}

body {
  background: var(--bg-primary) !important;
  overflow-x: hidden;
}

.narrow-container {
  width: min(1000px, 90%);
  margin: auto;
}

.container {
  width: min(var(--container-width), 90%);
  margin: auto;
}

.wide-container {
  width: min(var(--wide-container-width), 90%);
  margin: auto;
}

.custom-button {
  color: var(--white);
  border: none;
  outline: none;
  background: var(--primary-100);
  padding: 10px 35px;
  border-radius: 35px;
  cursor: pointer;
  transition: 0.5s;
  border: 1px solid var(--primary-100);
}

.custom-button:hover {
  background: transparent;
  color: var(--primary-100);
}

.pt-110 {
  padding-top: 110px;
}

.pt-90 {
  padding-top: 92px;
}

.not-available {
  text-align: center;
  margin: 20px 0 !important;
  color: var(--primary-100);
  font-weight: 400;
  font-size: 0.9rem;
}



/* =============== Custom Fonts =============== */
@font-face {
  font-family: 'grandSlang';
  src: url('../../src/fonts/GrandSlang-Roman.ttf') format('truetype')
}


.grandSlang {
  font-family: 'grandSlang', sans-serif;
}



/* ----------------------------- Jodit Status bar ----------------------------- */
.jodit-status-bar__item.jodit-status-bar__item-right a{
  display: none;
}


/* ----------------------------- Swiper slider custom ----------------------------- */
.swiper,
swiper-container {
  overflow: visible;
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: var(--swiper-pagination-bottom, 8px);
  top: calc(100% + 20px);
  left: 0;
  width: 100%;
}

.swiper-pagination-bullet-active {
  height: 10px;
  width: 25px;
  border-radius: 5px;
  background: var(--primary-100);
  opacity: var(--swiper-pagination-bullet-opacity, 1);
}

/* ----------------------------- Loader ----------------------------- */

.loader-style {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  background: var(--bg-primary);
  left: 0;
  top: 0;
}

/* ----------------------------- Section Title ----------------------------- */
.section-title {
  position: relative;
  width: fit-content;
}

.section-title .box-before {
  height: 30px;
  width: 30px;
  border-radius: 5px;
  background: var(--primary-6);
  position: absolute;
  left: -30px;
  top: -35px;
}

.section-title p {
  color: var(--primary-100);
  font-size: 0.9rem;
  font-weight: 600;
  position: relative;
  left: 8px;
}

.section-title p::before {
  content: "";
  height: 25px;
  width: 25px;
  border-radius: 5px;
  background: var(--primary-6);
  position: absolute;
  left: -8px;
  top: -3px;
}

/* ----------------------------- Section Background ----------------------------- */
.left-top-bg {
  width: 900px;
  height: 900px;
  background: var(--secondary-20);
  border-radius: 50%;
  position: absolute;
  left: -450px;
  top: -450px;
  z-index: -1;
  backdrop-filter: blur(2px);
  box-shadow: 0px 0px 250px 10px rgba(255, 216, 217, 0.5);
}

.right-bottom-bg {
  width: 400px;
  height: 400px;
  background: var(--primary-6);
  border-radius: 50%;
  position: absolute;
  right: 0px;
  bottom: -300px;
  z-index: -1;
  backdrop-filter: blur(2px);
  box-shadow: 0px 0px 250px 65px rgba(88, 40, 220, 0.1);
}

/* ----------------------------- Go To Top ----------------------------- */
.go-to-top {
  position: fixed;
  right: 40px;
  bottom: 50px;
  z-index: 4;
}

.go-to-top .to-back-text {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  gap: 20px;
  transition: 0.5s;
  cursor: pointer;
}

.go-to-top .to-back-text:hover {
  transform: translateY(-10px);
  gap: 28px;
}

.go-to-top .to-back-text .line-before {
  height: 80px;
  width: 1px;
  background: var(--dark-85);
  transition: 0.5s;
  transition-delay: 0.1s;
}

.go-to-top .to-back-text:hover .line-before {
  height: 95px;
  background: var(--primary-100);
}

.go-to-top .to-back-text p {
  color: var(--dark-85);
  font-size: 0.9rem;
  font-weight: 500;
  text-transform: uppercase;
  transform: rotate(180deg);
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transition: 0.5s;
}

.go-to-top .to-back-text:hover p {
  color: var(--primary-100);
}

.go-to-top .arrow-up {
  display: none;
}

.go-to-top .arrow-up .box {
  height: 50px;
  width: 50px;
  display: grid;
  place-content: center;
  background: var(--primary-100);
  border: 2px solid var(--bg-primary);
  border-radius: 10px;
  cursor: pointer;
}

/* ----------------------------- Scroll bar ----------------------------- */
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgb(231, 231, 231);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(197, 197, 197, 0.8);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(197, 197, 197, 1);
}

/* ----------------------------- Navigation ----------------------------- */
.navigation .hamburger-icon {
  display: none;
  transition: 0.5s;
}

.mobile-items {
  display: none;
}

.navigation-bg {
  width: 100%;
  background: var(--white-30);
  z-index: 6;
  left: 0;
  position: fixed;
  backdrop-filter: blur(10px);
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 25px 0; */
  position: relative;
  width: min(var(--wide-container-width), 90%);
  left: 50%;
  transform: translateX(-50%);
}

.navigation::after {
  position: absolute;
  content: "";
  height: 1px;
  width: 100%;
  background: var(--black-20);
  left: 0;
  bottom: 0;
}

.navigation .logo img {
  width: 30px;
  margin-bottom: -7px;
  position: relative;
  z-index: 20;
}

.navigation .items {
  display: flex;
  gap: 30px;
}

.navigation .items a {
  font-size: 0.9rem;
  color: var(--dark-85);
  font-weight: 500;
  transition: 0.5s;
  position: relative;
  padding: 35px 0;
}

.navigation .items a.active {
  position: relative;
  color: var(--primary-100);
  font-weight: 600;
  transition: 0.5s;
}

.navigation .items a.active::after {
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  background: var(--primary-100);
  left: 0;
  bottom: 0px;
  transition: 0.5s;
}

.navigation .items a::after {
  position: absolute;
  content: "";
  height: 2px;
  width: 0%;
  background: var(--primary-100);
  left: 0;
  bottom: 0px;
  transition: 0.5s;
}

.navigation .items a:hover::after {
  width: 100%;
}

/* ----------------------------- Hero Section ----------------------------- */
.hero-section {
  min-height: 100vh;
  padding-top: 130px;
  display: flex;
  flex-direction: column !important;
  justify-content: space-between;
}

.hero-section .top-items {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-section .top-items .heading {
  font-size: 4rem;
  text-align: center;
  width: min(950px, 95%);
  line-height: 75px;
  color: var(--dark-85);
  /* background: -webkit-linear-gradient(0turn, var(--dark-85), var(--dark-70));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
  margin-bottom: 30px !important;
  font-weight: 300;
}

.hero-section .top-items .heading span {
  font-weight: 600;
}

.hero-section .top-items .heading .color {
  color: var(--primary-100) !important;
  text-decoration: underline;
}

.hero-section .top-items .description {
  font-size: 1rem;
  text-align: center;
  width: min(800px, 95%);
  line-height: 20px;
  color: var(--dark-85);
  margin-bottom: 40px !important;
}

.hero-section .top-items .cta {
  display: flex;
  align-items: center;
  position: relative;
  gap: 25px;
  background: var(--primary-100);
  padding: 10px 10px 10px 20px;
  border-radius: 35px;
  cursor: pointer;
  transition: 0.5s;
  border: 1px solid var(--primary-100);
  transition: 0.5s;
  margin-bottom: 40px;
}

.hero-section .top-items .cta:hover {
  gap: 35px;
}

.hero-section .top-items .shape-one {
  position: absolute;
  bottom: 0;
  right: 25%;
  z-index: -1;
}

.hero-section .top-items .shape-two {
  position: absolute;
  bottom: 0;
  left: 25%;
  bottom: -50px;
  transform: rotate(90deg);
  z-index: -1;
}

.hero-section .top-items .cta .button {
  position: relative;
  background: transparent;
  color: var(--white);
  border: none;
  outline: none;
}

.hero-section .top-items .cta .right-arrow {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-section .hero-bottom-img {
  position: relative;
  bottom: 50px;
  width: 100%;
}

.hero-section .hero-bottom-img img {
  width: 100%;
}


/* ----------------------------- Achievements ----------------------------- */
.achievements {
  display: flex;
  align-items: flex-start;
  margin: 70px 0;
  gap: 15%;
  width: 100%;
}

.achievements .title {
  font-size: 2rem;
  font-weight: 600;
  color: var(--dark-70);
}

.achievements .counts {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.achievements .achievement .achievement-name {
  font-size: 0.8rem;
  font-weight: 400;
  color: var(--dark-100);
  line-height: 15px;
  margin-bottom: 20px;
}

.achievements .achievement .achievement-number {
  font-size: 4rem;
  font-weight: 600;
  color: var(--dark-70);
}

/* ----------------------------- Service we provide ----------------------------- */
.service-provide {
  background: var(--primary-5);
  padding: 100px 0;
  position: relative;
}

.service-provide .circles {
  position: absolute;
  right: 0;
  top: 0;
}

.service-provide .heading {
  font-size: 3.3rem;
  line-height: 60px;
  margin: 30px 0 50px 0 !important;
  background: -webkit-linear-gradient(0turn, var(--dark-85), var(--dark-50));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.service-provide .contents .service {
  padding: 55px 0 1px 0;
  position: relative;
  cursor: pointer;
}

.service-provide .contents .service::before,
.service-provide .contents .service::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  left: 0;
  background: var(--dark-50);
}

.service-provide .contents .service::before {
  top: -1px;
}

.service-provide .contents .service::after {
  bottom: 0;
}

.service-provide .contents .service .count-name-action {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  margin-bottom: 45px;
}

.service-provide .contents .service .count-name-action .count-name {
  display: flex;
  gap: 30px;
}

.service-provide .contents .service .count-name-action .count-name .count p {
  font-size: 0.9rem;
  font-weight: 300;
  color: var(--dark-100);
}

.service-provide .contents .service .count-name-action .count-name .name {
  font-size: 20px;
  color: var(--dark-85);
  font-weight: 400;
  line-height: 20px;
}

.service-provide .contents .service .count-name-action .more-info {
  font-size: 1rem;
  font-weight: 400;
  color: var(--dark-85);
  position: relative;
  cursor: pointer;
}

.service-provide .contents .service .count-name-action .more-info::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  left: 0;
  background: var(--dark-50);
  bottom: -2px;
}

.service-provide .contents .service .count-name-action .more-info-icon {
  transition: 0.5s;
  display: none;
}

.service-provide .contents .service .count-name-action .more-info-icon.active {
  transform: rotate(135deg);
}

.service-provide .contents .service .service-details-image {
  overflow: hidden;
  max-height: 0;
  transition: all 0.8s cubic-bezier(0, 1, 0, 1);

  display: flex;
  justify-content: space-between;

  margin-bottom: 20px;
}

.service-provide .contents .service .service-details-image.show {
  height: auto;
  max-height: 1000px;
  transition: all 0.8s cubic-bezier(1, 0, 1, 0);
}

.service-provide .contents .service .service-details-image .description-action {
  width: 60%;
}

.service-provide .contents .service .service-details-image .description-action .detail {
  font-size: 1rem;
  color: var(--dark-70);
  font-weight: 400;
  margin-bottom: 40px !important;
  white-space: pre-line;
  text-align: justify;
}

.service-provide .contents .service .service-details-image .description-action .lets-work {
  display: flex;
  align-items: center;
  gap: 35px;
  transition: 0.5s;
}

.service-provide .contents .service .service-details-image .description-action .lets-work a {
  font-size: 1rem;
  font-weight: 500;
  color: var(--dark-100);
  position: relative;
  cursor: pointer;
}

.service-provide .contents .service .service-details-image .description-action .lets-work a::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  left: 0;
  background: var(--dark-50);
  bottom: -6px;
}

.service-provide .contents .service .service-details-image .description-action .lets-work span {
  font-size: 1rem;
  font-weight: 500;
  color: var(--dark-100);
  position: relative;
  cursor: pointer;
}

.service-provide .contents .service .service-details-image .description-action .lets-work span::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  left: 0;
  background: var(--dark-50);
  bottom: -6px;
}

.service-provide .contents .service .service-details-image .description-action .lets-work img {
  height: 45px;
  width: 45px;
  transition: 0.5s;
}

.service-provide .contents .service .service-details-image .description-action .lets-work:hover img {
  transform: rotate(45deg) translate(-2px, -10px);
  margin-top: 10px;
}

.service-provide .contents .service .service-details-image .image {
  width: 30%;
}

.service-provide .contents .service .service-details-image .image img {
  width: 100%;
  height: auto;
  border-radius: 30px 30px 0 0;
}

/* ----------------------------- Our Work Process ----------------------------- */
.work-process {
  background-image: url("../images/triangles.svg");
  background-size: cover;
  /* background-position: center; */
  height: 100%;
  margin: 10px;
  padding: 100px 0;
}

.work-process .process-steps {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-top: 30px;
}

.work-process .process-steps .process {
  display: flex;
  gap: 6px;
  align-items: flex-start;
}

.work-process .process-steps .process .step-line {
  display: flex;
  align-items: center;
  gap: 6px;
}

.work-process .process-steps .process .step-line .step-count {
  color: var(--primary-100);
  font-size: 0.9rem;
  font-weight: 600;
}

.work-process .process-steps .process .step-line .line {
  height: 2px;
  width: 35px;
  background: linear-gradient(0.25turn, var(--primary-100), var(--bg-primary));
  position: relative;
  bottom: 1px;
}

.work-process .process-steps .process .process-detail .process-name {
  font-size: 20px;
  font-weight: 600;
  color: var(--dark-85);
  line-height: 22px;
  margin-bottom: 15px !important;
  white-space: pre-line;
}

.work-process .process-steps .process .process-detail .process-desc {
  font-size: 1rem;
  font-weight: 400;
  color: var(--dark-85);
  margin-bottom: 15px !important;
  white-space: pre-line;
}

.work-process .process-steps .process .process-detail .steps li {
  color: var(--dark-85);
  font-size: 0.9rem;
  font-weight: 600;
  list-style: url("../images/arrowRightPrimarySmall.svg");
  margin-bottom: 5px;
  margin-left: 16px;
}

/* ----------------------------- FAQ ----------------------------- */
.faqs {
  padding: 100px 0;
}

.faqs .heading {
  font-size: 3.3rem;
  line-height: 60px;
  margin-bottom: 50px !important;
  background: -webkit-linear-gradient(0turn, var(--dark-100), var(--dark-50));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

.faqs .contents .faq {
  padding: 55px 0 50px 0;
  display: flex;
  align-items: flex-start;
  position: relative;
  cursor: pointer;
  white-space: pre-line;
}

.faqs .contents .faq::before,
.faqs .contents .faq::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  left: 0;
  background: var(--dark-50);
}

.faqs .contents .faq::before {
  top: -1px;
}

.faqs .contents .faq::after {
  bottom: 0;
}

.faqs .contents .faq .count {
  width: 10%;
}

.faqs .contents .faq .faq-name-info {
  width: 80%;
}

.faqs .contents .faq .action {
  width: 10%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.faqs .contents .faq .count p {
  font-size: 1rem;
  font-weight: 300;
  color: var(--dark-100);
}

.faqs .contents .faq .faq-name-info .name {
  font-size: 20px;
  color: var(--dark-85);
  font-weight: 400;
}

.faqs .contents .faq .faq-name-info .info .detail {
  font-size: 1rem;
  color: var(--dark-70);
  font-weight: 400;
  margin-top: 35px !important;
}

.faqs .contents .faq .action .more-info {
  font-size: 2rem;
  font-weight: 200;
  color: var(--dark-85);
  cursor: pointer;
  line-height: 30px;
  transition: all 0.7s;
}

.faqs .contents .faq .faq-name-info .info {
  overflow: hidden;
  max-height: 0;
  transition: all 0.7s cubic-bezier(0, 1, 0, 1);
}

.faqs .contents .faq .faq-name-info .info.show {
  height: auto;
  max-height: 1000px;
  transition: all 0.7s cubic-bezier(1, 0, 1, 0);
}

.faqs .contents .faq .action .more-info.show {
  transform: rotate(135deg);
}

/* ----------------------------- Why Choose Us ----------------------------- */
.choose-us {
  padding: 30px 0;
}

.choose-us .content {
  display: flex;
  justify-content: space-between;
  gap: 80px;
  margin-top: 30px;
}

.choose-us .content .left-content {
  width: 50%;
}

.choose-us .content .right-content {
  width: 50%;
  overflow: hidden;
  min-height: 350px;
  /* background: red; */
  height: 100%;
}

.choose-us .content .left-content .title {
  font-size: 20px;
  font-weight: 600;
  color: var(--dark-85);
  line-height: 22px;
  margin-bottom: 15px !important;
  white-space: pre-line;
}

.choose-us .content .left-content .desc {
  font-size: 1rem;
  font-weight: 400;
  color: var(--dark-85);
  margin-bottom: 15px !important;
  white-space: pre-line;
}

.choose-us .content .left-content .steps li {
  color: var(--dark-85);
  font-size: 0.9rem;
  font-weight: 600;
  list-style: url("../images/arrowRightPrimarySmall.svg");
  margin-bottom: 5px;
  margin-left: 16px;
}

.choose-us .content .right-content img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 15px;
}

/* ----------------------------- What our client's says ----------------------------- */
.client-says {
  padding: 80px 0 30px 0;
}

.client-says .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.client-says .title {
  font-size: 3.3rem;
  line-height: 60px;
  margin-bottom: 30px !important;
  background: -webkit-linear-gradient(0turn, var(--dark-85), var(--dark-50));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.client-says .content {
  display: flex;
  gap: 50px;
}

.client-says .content .review {
  width: 100%;
  overflow: hidden;
  min-height: 300px;
}

.client-says .content .review .review-content {
  width: min(1000px, 90%);
}

.client-says .content .review .review-content .name-info {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 25px;
}

.client-says .content .review .review-content .name-info .client-img {
  height: 55px;
  width: 55px;
  border-radius: 50%;
  object-fit: cover;
}

.client-says .content .review .review-content .name-info .name-position .name {
  font-size: 20px;
  font-weight: 600;
  color: var(--dark-85);
  line-height: 30px;
}

.client-says .content .review .review-content .name-info .name-position .position {
  color: var(--dark-85);
  font-size: 0.9rem;
  font-weight: 400;
  position: relative;
  padding-left: 40px !important;
}

.client-says .content .review .review-content .name-info .name-position .position::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 30px;
  background: var(--dark-50);
  left: 0;
  top: 10px;
}

.client-says .content .review .review-content .statement q {
  color: var(--dark-85);
  font-size: 1rem;
  font-weight: 400;
  white-space: pre-line;
}

.client-says .content .action {
  width: 10%;
  display: flex;
  justify-content: flex-end;
}

.client-says .content .action img {
  width: 50px;
  cursor: pointer;
}

.client-says .content .review .swiper-button-prev,
.client-says .content .review .swiper-rtl .swiper-button-next {
  left: calc(100% - 150px);
  bottom: 90px;
}

.client-says .content .review .swiper-button-next:after {
  /* content: url(../images/arrowRight.svg);
    position: absolute;
    left: calc(100% - 45px);
    bottom: 60px; */
  content: "";
}

.client-says .content .review .swiper-button-prev:after {
  /* content: url(../images/arrowRight.svg);
    position: absolute;
    left: 100%;
    transform: rotate(180deg);
    bottom: 100%; */
  content: "";
}

/* ----------------------------- Got a project ----------------------------- */
.got-a-project {
  padding: 100px;
  margin-bottom: 50px;
  background: var(--primary-100);
  background-image: url("../images/boxs.svg");
  background-size: cover;
  background-position: center;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
}

.got-a-project .title {
  font-size: 4rem;
  line-height: 80px;
  color: var(--gray);
  font-weight: 700;
}

.got-a-project a .action-img {
  height: 150px;
  width: 150px;
  object-fit: cover;
  border-radius: 50%;
}

.got-a-project a.button {
  display: none;
}

/* ----------------------------- Footer ----------------------------- */
.footer {
  background: var(--secondary-20);
  padding: 100px 0 55px 0;
  position: relative;
  overflow: hidden;
}

.footer .circles {
  position: absolute;
  right: 0;
  top: 0;
}

.footer .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 80px;
}

.footer .heading .logo h3 {
  color: var(--primary-100);
  font-size: 25px;
  font-weight: 800;
  text-transform: uppercase;
}

.footer .heading .logo a img {
  width: 100px;
}

.footer .heading .get-started {
  display: flex;
  gap: 40px;
  align-items: center;
}

.footer .heading .get-started h3 {
  color: var(--dark-70);
  font-size: 20px;
  font-weight: 600;
}

.footer .heading::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  background: var(--black-20);
  left: 0;
  bottom: -40px;
}

.footer .email-links {
  display: flex;
  gap: 60px;
  margin-bottom: 80px;
}

.footer .email-links .enter-email {
  width: 30%;
}

.footer .email-links .links {
  width: 70%;
}

.footer .email-links .enter-email .email-heading {
  font-size: 20px;
  font-weight: 600;
  color: var(--dark-85);
  margin-bottom: 66px !important;
}

.footer .email-links .enter-email form {
  display: flex;
  width: 100%;
  position: relative;
}

.footer .email-links .enter-email form input {
  width: 100%;
  padding-bottom: 10px;
  padding-right: 65px;
  color: var(--dark-85);
  border: none;
  border-bottom: 1px solid var(--black-20);
  background: transparent;
  outline: none;
  font-size: 1rem;
}

.footer .email-links .enter-email form button {
  height: 50px !important;
  width: 50px !important;
  background: var(--primary-100);
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  transition: 0.5s;
  position: absolute;
  right: 0;
  bottom: 0;
}

.footer .email-links .enter-email form button:hover {
  transform: translateX(5px);
}

.footer .email-links .links {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.footer .email-links .links .column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footer .email-links .links .column .link-title {
  font-size: 20px;
  font-weight: 600;
  color: var(--dark-85);
}

.footer .email-links .links .column .link {
  color: var(--dark-70);
  font-size: 1rem;
  font-weight: 400;
  transition: 0.5s;
  position: relative;
  width: fit-content;
}

.footer .email-links .links .column .link:hover {
  transform: translateX(8px);
  color: var(--primary-100);
}

.footer .email-links .links .column .link::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 0%;
  background: var(--primary-100);
  left: 0;
  bottom: -2px;
  transition: 0.5s;
  transition-delay: 0.2s;
}

.footer .email-links .links .column .link:hover::after {
  width: 100%;
}

.footer .terms-policy {
  display: flex;
  gap: 100px;
  width: 100%;
  position: relative;
  margin-bottom: 100px;
}

.footer .terms-policy a {
  font-size: 1.1rem;
  color: var(--dark-85);
  font-weight: 500;
  transition: 0.5s;
  position: relative;
  width: fit-content;
  transition: 0.5s;
}

.footer .terms-policy a::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 0%;
  background: var(--primary-10);
  left: 0;
  z-index: -1;
  transition: 0.5s;
}

.footer .terms-policy a:hover {
  color: var(--primary-100);
}

.footer .terms-policy a:hover:after {
  width: 100%;
}

.footer .terms-policy::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  background: var(--black-20);
  left: 0;
  bottom: -40px;
}

.footer .copyright {
  text-align: center;
  color: var(--dark-50);
  font-size: 0.9rem;
}

/* ----------------------------- About Us ----------------------------- */
/* ------------ Heading ------------ */

.about-heading {
  padding: 100px 0 150px 0;
}

.about-heading .title {
  margin-top: 15px !important;
  font-size: 3.5rem;
  font-weight: 200;
  color: var(--dark-85);
  width: min(650px, 90%);
  line-height: 70px;
}

.heading-line-svg {
  position: absolute;
  right: 0;
  top: 250px;
}

/* ------------ Why Different ------------ */
.why-different {
  background: var(--primary-5);
  padding: 100px 0;
  position: relative;
}

.why-different .arrowSvg {
  position: absolute;
  left: 250px;
  bottom: -70px;
  z-index: -1;
}

.why-different section {
  display: flex;
  gap: 30px;
}

.why-different section h2.section-title {
  margin-top: 15px !important;
  font-size: 3rem;
  font-weight: 200;
  color: var(--dark-85);
  width: min(650px, 90%);
  line-height: 55px;
  width: 30%;
}

.why-different section .points {
  width: 70%;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 80px;
}

.why-different section .points .point {
  position: relative;
}

.why-different section .points .point::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 1px;
  background: var(--dark-50);
  left: -30px;
  top: 0;
}

.why-different section .points .point .count {
  color: var(--dark-85);
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 8px !important;
}

.why-different section .points .point h5.title {
  color: var(--dark-85);
  font-size: 25px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 20px !important;
  white-space: pre-line;
}

.why-different section .points .point .desc {
  color: var(--dark-70);
  font-size: 1rem;
  white-space: pre-line;
}

/* ------------ We Value ------------ */
.we-value {
  padding: 0px 0 130px 0;
  position: relative;
}

.we-value section h2.section-title {
  margin-top: 15px !important;
  font-size: 3rem;
  font-weight: 200;
  color: var(--dark-85);
  line-height: 55px;
  width: 300px;
  text-align: center;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  top: 320px;
}

.we-value section .points .row {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: space-between;
}

.we-value section .points .row.row-one .point:nth-child(2),
.we-value section .points .row.row-two .point:nth-child(2) {
  text-align: end;
}

.we-value section .points .row .point.center {
  text-align: center;
}

.we-value section .points .row.row-two {
  padding: 0 100px;
}

.we-value section .points .row.row-three {
  justify-content: center;
  margin-bottom: -55px;
}

.we-value section .points .point {
  width: 370px;
  margin-bottom: 55px;
}

.we-value section .points .point .count {
  color: var(--dark-85);
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 8px !important;
}

.we-value section .points .point h5.title {
  color: var(--dark-85);
  font-size: 25px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 20px !important;
  white-space: pre-line;
}

.we-value section .points .point .desc {
  color: var(--dark-70);
  font-size: 1rem;
  white-space: pre-line;
}

/* ------------ Our Team ------------ */
.team-data-m {
  display: none;
}

.our-team {
  background: var(--primary-5);
  padding: 100px 0;
  position: relative;
}

.our-team .team-info .team-heading {
  width: 100%;
  margin-bottom: 50px;
}

.our-team .team-info .team-heading .title {
  font-size: 3rem;
  font-weight: 200;
  color: var(--dark-85);
  line-height: 55px;
  text-align: center;
  margin-bottom: 15px !important;
}

.our-team .team-info .team-heading .desc {
  text-align: center;
  display: flex;
  justify-content: center;
  color: var(--dark-70);
  white-space: pre-line;
}

.our-team .team-info .team-data {
  display: grid;
  place-items: center;
  position: relative;
}

.our-team .team-info .team-data .team-member {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 100%;
}

.our-team .team-info .team-data .team-member:hover .others-info {
  top: 0;
}

.our-team .team-info .team-data .team-member img {
  width: 100%;
  height: calc(100% + 100px);
  max-height: 600px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 5px;
  position: relative;
}

.our-team .team-info .team-data .team-member .others-info {
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(to top, var(--primary-100), var(--primary-0));
  border-radius: 5px;
  top: 100%;
  left: 0;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  transition: 1s cubic-bezier(0.76, 0, 0.24, 1);
}

.our-team .team-info .team-data .team-member .others-info .name {
  font-size: 20px;
  color: var(--bg-primary);
  font-weight: 500;
  margin-bottom: 5px !important;
}

.our-team .team-info .team-data .team-member .others-info .role {
  font-size: 12px;
  color: var(--bg-primary);
  font-weight: 300;
  margin-bottom: 5px !important;
}

.our-team .team-info .team-data .team-member .others-info .social {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.our-team .team-info .team-data .team-member .others-info .social a img {
  height: 25px;
  width: 25px;
  border-radius: 50%;
}

/* ------------ Start Project ------------ */
.start-project {
  padding: 100px;
  margin: 50px 0;
  background: var(--primary-100);
  background-image: url("../images/boxs.svg");
  background-size: cover;
  background-position: center;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
}

.start-project .title {
  font-size: 4rem;
  line-height: 80px;
  color: var(--gray);
  font-weight: 700;
}

.start-project a .action-img {
  height: 150px;
  width: 150px;
  object-fit: cover;
  border-radius: 50%;
}




/* ----------------------------- Projects ----------------------------- */
/* ------------ Heading ------------ */

.projects-heading {
  padding: 100px 0 30px 0;
}

.projects-heading .title {
  margin-top: 15px !important;
  font-size: 3.5rem;
  font-weight: 200;
  color: var(--dark-85);
  width: min(650px, 90%);
  line-height: 70px;
  white-space: pre-line;
}

.projects-heading .desc {
  margin-top: 35px !important;
  font-size: 1rem;
  font-weight: 300;
  color: var(--dark-100);
  width: min(750px, 100%);
  white-space: pre-line;
}

.projects-heading .scroll {
  display: flex;
  flex-direction: column;
  margin-top: 45px;
  position: relative;
  left: 100px;
  width: fit-content;
  align-items: center;
}

.projects-heading .scroll .text {
  color: var(--dark-85);
  font-weight: 300;
  font-size: 0.9rem;
}

.projects-heading .scroll .line-after {
  height: 370px;
  width: 1px;
  background: var(--dark-50);
}

.heading-line-svg {
  position: absolute;
  right: 0;
  top: 250px;
}










/* ------------ All Projects ------------ */
.all-projects {
  margin-bottom: 180px;
  width: 100%;
}

.all-projects .project-data {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.all-projects .project-data .project {
  background-color: var(--white);
}

.all-projects .project-data .project .project-bulletpoint {
  width: 8px;
  height: 8px;
  background-color: var(--primary-100);
}

.all-projects .project-data .project .project-title {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 14px;
  color: var(--dark-100);
  padding: 12px 20px;
}

.all-projects .project-data .project .project-img {
  width: 100%;
  overflow: hidden;
}

.all-projects .project-data .project img {
  width: 100%;
  object-fit: cover;
  border-top: 1px solid var(--gray);
  border-bottom: 1px solid var(--gray);
}

.all-projects .project-data .project .project-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px 20px 20px;
}

.all-projects .project-data .project .project-details .project-name {
  color: var(--primary-100);
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 10px;
}

.all-projects .project-data .project .project-details .year-live-link {
  display: flex;
  align-items: center;
  gap: 10px;
}

.all-projects .project-data .project .project-details .project-year {
  color: var(--dark-50);
  font-size: 12px;
  font-weight: 500;
}

.all-projects .project-data .project .project-details .year-live-link .live-link {
  color: var(--primary-100);
}

.all-projects .project-data .project .project-details .project-icon {
  color: var(--primary-100);
  font-size: 22px;
}













/* ---------------- project details page ----------------- */
.project-info .project-name {
  background: var(--dark-100);
  padding: 150px 70px;
  color: var(--bg-primary);
  margin-bottom: 30px;
  position: relative;
}

.project-info .project-name h1 {
  font-size: 128px;
  color: var(--bg-primary);
  font-weight: 300;
  text-transform: uppercase;
  line-height: 128px;
}

.go-back-to-projects {
  position: absolute;
  height: 35px;
  width: 35px;
  transform: rotate(180deg);
  top: 70px;
  left: 70px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.project-info .thumbnail-othersInfo {
  margin-bottom: 60px;
  display: flex;
  gap: 30px;
}

.project-info .thumbnail-othersInfo .thumbnail {
  width: 65%;
}

.project-info .thumbnail-othersInfo .thumbnail img {
  width: 100%;
}

.project-info .thumbnail-othersInfo .others-info {
  width: calc(35% - 35px);
  text-align: justify;
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.project-info .thumbnail-othersInfo .others-info .info .title {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--dark-100);
  position: relative;
  width: fit-content;
  font-family: "Montserrat", sans-serif;
}

.project-info .thumbnail-othersInfo .others-info .info .title::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  background: var(--dark-100);
  left: 0;
  bottom: -2px;
}

.project-info .thumbnail-othersInfo .others-info .info .data {
  font-size: 14px;
  color: var(--dark-70);
  margin-top: 12px !important;
}

.project-info .thumbnail-othersInfo .others-info .info a.data {
  color: var(--primary-100);
  font-weight: 400;
  position: relative;
}

.project-info .thumbnail-othersInfo .others-info .info a.data::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 0%;
  background: var(--primary-100);
  left: 0;
  bottom: -2px;
  transition: .6s;
}

.project-info .thumbnail-othersInfo .others-info .info a.data:hover:after {
  width: 100%;
}

.detailed-info .short-description {
  font-size: 48px;
  color: var(--dark-100);
  font-weight: 300;
  margin-bottom: 30px !important;
}

.detailed-info .image-slider {
  margin-bottom: 45px;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 50px 30px -40px rgba(0, 0, 0, 0.1);
}

.detailed-info .image-slider img {
  width: 100%;
  object-fit: cover;
}

.detailed-info .detailed-description {
  margin-bottom: 45px;
}

.detailed-info .detailed-description div>* {
  font-family: "Montserrat", sans-serif;
}

.detailed-info .check-project {
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 75px;
}

.detailed-info .check-project .title {
  font-size: 40px;
  font-weight: 300;
  color: var(--dark-100);
}

.detailed-info .check-project a {
  padding: 15px 30px;
  background: var(--dark-100);
  color: var(--bg-primary);
  font-size: 20px;
  font-weight: 300;
  border-radius: 35px;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  display: none;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  display: none;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 15px 10px 0;
  border-color: transparent var(--primary-100) transparent transparent;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev::after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0px 10px 15px;
  border-color: transparent transparent transparent var(--primary-100);
}





/* ----------------------------- Contact ----------------------------- */
.contact .contact-heading {
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
}

.contact .contact-heading .heading-texts {
  width: min(800px, 95%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact .contact-heading .heading-texts .title {
  margin-top: 15px !important;
  font-size: 3.5rem;
  font-weight: 300;
  color: var(--dark-85);
  width: min(650px, 90%);
  line-height: 65px;
  text-align: center;
}

.contact .contact-heading .heading-texts .desc {
  margin-top: 25px !important;
  font-size: 1rem;
  font-weight: 400;
  color: var(--dark-100);
  width: min(750px, 100%);
  text-align: center;
  white-space: pre-line;
}

.contact-form {
  width: min(800px, 100%);
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 150px;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 60px;
  width: 100%;
}

.contact-form form .name-email {
  display: flex;
  align-items: center;
  gap: 70px;
  width: 100%;
}

.contact-form form .name-email .name,
.contact-form form .name-email .email,
.contact-form form .message {
  width: 100%;
  position: relative;
}

.contact-form form input,
.contact-form form textarea {
  width: 100%;
  border: none;
  background: transparent;
  outline: none;
  font-size: 16px;
  color: var(--dark-85);
  border-bottom: 1px solid var(--black-20);
  padding-bottom: 10px;
  position: relative;
  z-index: 3;
}

.contact-form form textarea {
  height: 70px !important;
  margin-bottom: -7px !important;
}

.contact-form form input~.line-after,
.contact-form form textarea~.line-after {
  position: absolute;
  height: 1px;
  width: 0%;
  background: var(--primary-100);
  bottom: 0px;
  transition: 0.9s;
  z-index: 4;
}

.contact-form form input:focus~.line-after,
.contact-form form textarea:focus~.line-after {
  width: 100%;
}

.contact-form form button {
  position: relative;
  z-index: 3;
  width: fit-content;
  margin: auto;
}

.contact-form .planeSvg {
  position: absolute;
  left: -250px;
  bottom: -430px;
  z-index: -1;
}

.contact .other-possible-way {
  width: min(800px, 100%);
  margin: auto;
  margin-bottom: 200px;
}

.contact .possible-ways {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-top: 30px;
}

.contact .possible-ways .ways {
  display: flex;
  gap: 6px;
  align-items: flex-start;
}

.contact .possible-ways .ways .ways-line {
  display: flex;
  align-items: center;
  gap: 6px;
}

.contact .possible-ways .ways .ways-line .ways-count {
  color: var(--primary-100);
  font-size: 0.9rem;
  font-weight: 600;
}

.contact .possible-ways .ways .ways-line .line {
  height: 2px;
  width: 35px;
  background: linear-gradient(0.25turn, var(--primary-100), var(--bg-primary));
  position: relative;
  bottom: 1px;
}

.contact .possible-ways .ways .ways-detail .ways-title {
  font-size: 20px;
  font-weight: 600;
  color: var(--dark-85);
  line-height: 22px;
  margin-bottom: 15px !important;
}

.contact .possible-ways .ways .ways-detail .contact-way {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.contact .possible-ways .ways .ways-detail .contact-way li {
  color: var(--dark-85);
  font-size: 0.9rem;
  font-weight: 600;
  list-style: url("../images/arrowRightPrimarySmall.svg");
  margin-bottom: 5px;
  margin-left: 16px;
  cursor: pointer;
  transition: 0.6s;
}

.contact .possible-ways .ways .ways-detail .contact-way li:hover {
  transform: translateX(8px);
}













/* ----------------------------- Error Page | 404 not found ----------------------------- */
.not-found {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.not-found .not-found-bg {
  height: 80%;
  width: 80%;
  background: var(--gray);
  position: absolute;
  right: 0;
  bottom: 0;
}

.not-found .not-found-bg .error-code-bg {
  position: absolute;
  color: rgba(32, 32, 33, 0.05);
  left: 0;
  bottom: -350px;
  font-size: 600px;
}

.not-found .not-found-bg .logo img {
  width: 50px;
  height: auto;
  position: relative;
  left: 0;
  top: -70px;
}

.not-found .not-found-bg .line {
  position: relative;
  width: 40%;
  height: 5px;
  left: 0px;
  top: -60px;
  background: var(--primary-100);
}

.not-found .not-found-bg .color {
  color: var(--primary-100);
  position: relative;
  left: -3px;
}

.not-found .not-found-bg .we-lost {
  text-transform: uppercase;
  font-size: 120px;
  line-height: 105px;
  color: var(--dark-85);
  font-weight: 900;
  position: relative;
  left: -2px;
  top: -50px;
}

.not-found .not-found-bg .error {
  text-transform: uppercase;
  font-size: 120px;
  line-height: 105px;
  color: var(--dark-85);
  font-weight: 900;
  position: relative;
  left: -2px;
  top: 25px;
  word-spacing: 10px;
}

.not-found .not-found-bg .action {
  position: relative;
  top: 120px;
  left: 0;
}

.not-found .not-found-bg .action a {
  font-size: 20px;
  font-weight: 900;
  color: var(--gray);
  text-transform: uppercase;
  background: var(--primary-100);
  padding: 18px 80px 18px 0;
  transition: 0.5s;
}

.not-found .not-found-bg .action a:hover {
  padding: 18px 100px 18px 18px;
}












/* ----------------------------- Login Section ----------------------------- */
.login {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: center;
  align-items: center;
}

.login .title {
  color: var(--primary-100);
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
}

.login form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: min(450px, 95%);
}

.login form input {
  background: transparent;
  border: none;
  outline: none;
  border-bottom: 1px solid var(--dark-50);
  padding: 10px 0;
  font-size: 1rem;
  transition: 0.5s;
  color: var(--dark-85);
}

.login form input:focus {
  border-bottom: 1px solid var(--dark-70);
}

.login form button {
  background: var(--primary-100);
  padding: 10px;
  font-size: 1rem;
  font-weight: 500;
  color: var(--white);
  border: none;
  outline: none;
  transition: 0.5s;
  cursor: pointer;
  border: 1px solid var(--primary-100);
}

.login form button:hover {
  border: 1px solid var(--primary-100);
  color: var(--primary-100);
  background: transparent;
}













/* ----------------------------- Others Pages ----------------------------- */
.text-color {
  color: var(--dark-85);
}

.page-bg-color,
.page-bg {
  height: 30vh;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.page-bg .title {
  color: var(--primary-100);
  position: relative;
  width: fit-content;
}

.page-bg .title::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 70%;
  background: var(--primary-100);
  left: 0;
  bottom: -2px;
}

.page-bg-color {
  position: absolute;
  left: 0;
  top: 0;
  background: var(--dark-5);
  z-index: -1;
}













/* ----------------------------- Admin Section ----------------------------- */

/* ------------ Common CSS ------------ */
.admin-container {
  width: calc(100% - 140px);
  margin: auto;
  position: relative;
  min-height: 100vh;
  z-index: 1;
  left: 45px;
  padding-bottom: 15px;
}

.admin-page-title h2 {
  color: var(--dark-85);
  font-size: 1.5rem;
  font-weight: 500;
  position: relative;
  padding: 20px 0 !important;
  margin-bottom: 27px !important;
}

.admin-page-title h2::after {
  content: "";
  position: absolute;
  height: 1px;
  width: calc(100% + 50px);
  background: var(--gray);
  left: -25px;
  bottom: 0;
}

svg {
  stroke: var(--gray);
  cursor: pointer;
}

span.number-count {
  font-size: 12px;
  margin-left: 10px !important;
}

/* ----- confirm box ----- */
.confirm-popup {
  position: fixed;
  height: 100vh;
  width: 100%;
  background: var(--black-20);
  backdrop-filter: blur(3px);
  left: 0;
  top: 0;
  z-index: 10;
  transform: scale(0);
  transition: 0.5s;
}

.confirm-popup.open {
  transform: scale(1);
}

.confirm-box {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: var(--bg-primary);
  padding: 40px;
  border-radius: 5px;
  border: 1px solid var(--primary-10);
  overflow-y: scroll;
}

.confirm-box .message {
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--dark-85);
  margin-bottom: 20px !important;
}

.confirm-box .message span {
  color: var(--danger);
}

.confirm-box .actions {
  display: flex;
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm-box .actions button {
  padding: 10px 35px;
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--white);
  border-radius: 2px;
}

.confirm-box .actions button.cancel {
  background: var(--gray);
  color: var(--dark-85);
}

.confirm-box .actions button.delete {
  background: var(--danger);
  color: var(--white);
}












/* ------------ Admin Common Popup ------------ */
.common-popup-bg {
  position: fixed;
  height: 100vh;
  width: 100%;
  background: var(--black-20);
  backdrop-filter: blur(3px);
  left: 0;
  top: 0;
  z-index: 9;
  transform: scale(0);
  transition: 0.5s;
}

.common-popup-bg.open {
  transform: scale(1);
}

.common-popup {
  position: fixed;
  max-height: calc(100vh - 200px);
  height: auto;
  width: min(700px, 100%);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: var(--bg-primary);
  padding: 25px 30px;
  border-radius: 8px;
  border: 1px solid var(--primary-10);
  overflow-y: scroll;
}

.common-popup .common-popup-heading {
  color: var(--primary-100);
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px !important;
}

.common-popup form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
}

.common-popup form .input-field {
  position: relative;
}

.common-popup form .input-field span {
  position: absolute;
  font-size: 0.7rem;
  color: var(--white);
  background: var(--primary-100);
  padding: 1px 10px;
  left: 15px;
  top: -8px;
  z-index: 2;
  border-radius: 2px;
}

.common-popup form .input-field.jodit span {
  left: 0px;
  margin-top: 5px;
}

.common-popup form input,
.common-popup form textarea {
  width: 100%;
  padding: 15px;
  border: 1px solid var(--dark-50);
  background: transparent;
  font-size: 1rem;
  font-weight: 400;
  color: var(--dark-100);
  border-radius: 3px;
  outline: none;
  position: relative;
}

.common-popup form textarea {
  height: 120px;
}

.common-popup form input:focus,
.common-popup form textarea:focus {
  border: 1px solid var(--primary-100);
}

.common-popup form .actions {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-top: 20px;
}

.common-popup form .actions div.cancel,
.common-popup form .actions button.submit {
  width: 100%;
  padding: 10px;
  border-radius: 3px;
  font-size: 0.9rem;
  cursor: pointer;
  color: var(--dark-85);
  text-align: center;
  outline: none;
  border: none;
  font-weight: 500;
}

.common-popup form .actions div.cancel {
  background: var(--gray);
}

.common-popup form .actions button.submit {
  background: var(--primary-100);
  color: var(--white);
}

.common-popup form .add-image input {
  display: none;
}

.common-popup form .add-image label {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  justify-content: center;
  align-items: center;
  border: 2px dotted var(--dark-50);
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
}

.common-popup form .add-image label img {
  height: 30px;
  width: 30px;
}

.common-popup form .add-image p {
  color: var(--dark-70);
  font-size: 0.8rem;
  font-weight: 500;
}

.common-popup form .add-image p span {
  color: var(--dark-85);
  font-weight: 600;
}















/* ------------ Admin Navigation ------------ */
.admin-side-navigation {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 25px 25px;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  background: var(--primary-100);
  width: 90px;
}

.admin-side-navigation::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 1px;
  background: var(--gray);
  right: 0;
  top: 0;
}

.admin-side-navigation .logo svg {
  height: 32px;
  width: 32px;
  cursor: pointer;
}

.admin-side-navigation .navigation-items ul {
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
  z-index: 20;
}

.admin-side-navigation .navigation-items ul li a.active,
.admin-side-navigation a.active {
  position: relative;
  transition: 0.4s;
}

.admin-side-navigation .navigation-items ul li a.active::after,
.admin-side-navigation a.active::after {
  position: absolute;
  content: "";
  height: 5px;
  width: 5px;
  background: var(--gray);
  left: 50%;
  transform: translateX(-50%);
  bottom: -2px;
  border-radius: 50%;
  transition: 0.4s;
}















/* ------------ Admin Common Section ------------ */
.admin-common .common-section {
  background: var(--bg-primary);
  padding: 25px 30px;
  border-radius: 8px;
  border: 1px solid var(--primary-10);
  margin-bottom: 20px;
}

.admin-common .common-section .heading {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.admin-common .common-section .heading::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  background: var(--gray);
  left: 0;
  bottom: 0;
}

.admin-common .common-section .heading .title {
  font-size: 20px;
  color: var(--primary-100);
  font-weight: 500;
}

.admin-common .common-section .heading .actions {
  display: flex;
  gap: 40px;
}

.admin-common .common-section .heading .actions img {
  width: 30px;
  cursor: pointer;
}

.admin-common .common-section .heading .actions img.plus {
  transform: rotate(45deg);
}

.admin-common .common-section .body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.admin-common .common-section .body .data {
  display: flex;
}

.admin-common .common-section .body .data .content-title {
  width: 200px;
  color: var(--dark-85);
  font-weight: 600;
  font-size: 1rem;
}

.admin-common .common-section .body .data .content-data {
  width: calc(100% - 250px);
  color: var(--dark-70);
  font-weight: 400;
  font-size: 1rem;
}
















/* ------------ Admin Common Section / Number - Two/Three Nested ------------ */

.admin-common .common-section.number-two-nested .body .data .content-title {
  width: 60px;
  margin-top: 10px !important;
}

.admin-common .common-section.number-two-nested .body .data .content-data-nested-two {
  /* width: calc(100% - 60px); */
  width: 100%;
  border: 1px solid var(--dark-50);
  padding: 10px;
  border-radius: 4px;
}

.admin-common .common-section.number-two-nested .body .data .content-data-nested-two .nested-two-title {
  font-weight: 500;
  margin-bottom: 5px !important;
}

.admin-common .common-section.number-two-nested .body .data .content-data-nested-two .nested-two-title-heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.admin-common .common-section.number-two-nested .body .data .content-data-nested-two .temp-title,
.admin-common .common-section.number-two-nested .body .data .content-data-nested-two .temp-desc {
  color: var(--dark-85);
  font-weight: 600;
  font-size: 1rem;
  margin-right: 10px !important;
}

.admin-common .common-section.number-two-nested .body .data .content-data-nested-two .nested-two-title-heading .nested-two-title-actions {
  display: flex;
  align-items: center;
  gap: 40px;
}

.admin-common .common-section.number-two-nested .body .data .content-data-nested-two .nested-two-title-heading .nested-two-title-actions img {
  cursor: pointer;
}

.admin-common .common-section.number-two-nested .body .data .content-data-nested-two .nested-two-body {
  font-size: 14px;
  color: var(--dark-70);
  /* white-space: pre-line; */
}

.admin-common .common-section.number-two-nested .body .data .content-data-nested-two .nested-others-items {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid var(--dark-50);
  padding: 10px;
  border-radius: 3px;
  margin-top: 10px;
}

.admin-common .common-section.number-two-nested .body .data .content-data-nested-two .nested-others-items .nested-others-single-item {
  display: flex;
  align-items: center;
}

.admin-common .common-section.number-two-nested .body .data .content-data-nested-two .nested-others-items .nested-others-single-item .others-item-count {
  width: 70px !important;
  font-size: 1rem;
  font-weight: 600;
  color: var(--dark-85);
}

.admin-common .common-section.number-two-nested .body .data .content-data-nested-two .nested-others-items .nested-others-single-item .other-item-data {
  font-size: 0.9rem;
  color: var(--dark-85);
}

.admin-common .common-section.number-two-nested .body .data .content-data-nested-two .content-img {
  height: 150px;
  width: 110px;
  margin-top: 5px;
}

.admin-common .common-section.number-two-nested .body .data .content-data-nested-two .content-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
}














/* ------------ Admin Home/add service Section ------------ */
.common-popup .home-add-service form .home-add-service-selected-image {
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.common-popup .home-add-service form .home-add-service-selected-image .image {
  height: 100%;
  width: 220px;
  object-fit: cover;
}












/* ------------ Admin Home/work process section ------------ */
.common-popup .home-work-process-edit form .input-field.add-point .add {
  cursor: pointer;
  background: var(--primary-100);
  padding: 8px 15px;
  border-radius: 3px;
  position: absolute;
  right: 8px;
  top: 8px;
  color: white;
}

.common-popup .home-work-process-edit form .work-process-previous-point {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.common-popup .home-work-process-edit form .work-process-previous-point .point {
  background: var(--gray);
  padding: 5px 7px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.common-popup .home-work-process-edit form .work-process-previous-point .point .cancel-button {
  height: 20px;
  width: 20px;
  background: var(--primary-100);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 2px;
  line-height: 10px;
  cursor: pointer;
}














/* ------------ Admin Home/why choose us Section ------------ */
.admin-common .admin-home-why-choose-use .data {
  width: 100%;
}

.admin-common .admin-home-why-choose-use .nested-others-items {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid var(--dark-50);
  padding: 10px;
  border-radius: 3px;
  width: 100%;
}

.admin-common .admin-home-why-choose-use .nested-others-items .nested-others-single-item {
  display: flex;
  align-items: center;
}

.admin-common .admin-home-why-choose-use .nested-others-items .nested-others-single-item .others-item-count {
  width: 70px !important;
  font-size: 1rem;
  font-weight: 600;
  color: var(--dark-85);
}

.admin-common .admin-home-why-choose-use .nested-others-items .nested-others-single-item .other-item-data {
  font-size: 0.9rem;
  color: var(--dark-85);
}













/* ------------ Admin Home/client says Section ------------ */
.admin-home-client-says .body .data .content-data-nested-two {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.admin-home-client-says .body .data .content-data-nested-two .client-image-actions {
  display: flex;
  justify-content: space-between;
}

.admin-home-client-says .body .data .content-data-nested-two .client-image-actions .client-image {
  height: 55px;
  width: 55px;
  border-radius: 50%;
  object-fit: cover;
}

.admin-home-client-says .body .data .content-data-nested-two .client-image-actions .action {
  display: flex;
  align-items: center;
  gap: 40px;
}

.admin-home-client-says .body .data .content-data-nested-two .client-image-actions .action img {
  cursor: pointer;
}

.admin-home-client-says .body .data .content-data-nested-two .client-name,
.admin-home-client-says .body .data .content-data-nested-two .client-role,
.admin-home-client-says .body .data .content-data-nested-two .client-message {
  display: flex;
}

.admin-home-client-says .body .data .content-data-nested-two .client-name .name-title,
.admin-home-client-says .body .data .content-data-nested-two .client-role .role-title,
.admin-home-client-says .body .data .content-data-nested-two .client-message .message-title {
  width: 100px;
  color: var(--dark-85);
  font-weight: 600;
}

.admin-home-client-says .body .data .content-data-nested-two .client-name .name,
.admin-home-client-says .body .data .content-data-nested-two .client-role .role,
.admin-home-client-says .body .data .content-data-nested-two .client-message .message {
  color: var(--dark-85);
  font-weight: 400;
}

.common-popup .home-client-says form .home-client-says-selected-image {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.common-popup .home-client-says form .home-client-says-selected-image .image {
  height: 100%;
  width: 100px;
  object-fit: cover;
}














/* ------------ Admin About/team Section ------------ */
.admin-common .admin-about-team .heading .actions .close {
  transform: rotate(45deg);
}

.admin-common .admin-about-team .team-title-description {
  width: 100%;
  border: 1px solid var(--dark-50);
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.admin-common .admin-about-team .team-title-description .data,
.admin-common .admin-about-team .team-title-description .data .title-part {
  display: flex;
}

.admin-common .admin-about-team .team-title-description .data.title-section {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.admin-common .admin-about-team .team-title-description .data.title-section img {
  cursor: pointer;
}

.admin-common .admin-about-team .team-title-description .data .title,
.admin-common .admin-about-team .team-title-description .data .description {
  width: 200px;
  color: var(--dark-85);
  font-weight: 600;
  font-size: 1rem;
}

.admin-common .admin-about-team .team-title-description .data .title-data,
.admin-common .admin-about-team .team-title-description .data .description-data {
  color: var(--dark-70);
  font-weight: 400;
  font-size: 1rem;
}

.admin-common .admin-about-team .member-gap {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
}

.admin-common .admin-about-team .member-gap .section {
  width: 50%;
  border: 1px solid var(--dark-50);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 5px;
}

.admin-common .admin-about-team .member-gap .section .text {
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--dark-85);
}

.admin-common .admin-about-team .member-gap .section .number {
  font-size: 1rem;
  font-weight: 600;
  color: var(--dark-85);
}

.admin-common .admin-about-team .member-gap .section .edit {
  cursor: pointer;
}

.admin-common .admin-about-team .body .data {
  display: flex;
  align-items: center;
}

.admin-common .admin-about-team .body .data .member-img img {
  height: 55px;
  width: 55px;
  object-fit: cover;
  border-radius: 50%;
}

.admin-common .admin-about-team .body .data .member-img {
  width: 8%;
}

.admin-common .admin-about-team .body .data .name {
  width: 15%;
  font-weight: 500;
  color: var(--dark-85);
}

.admin-common .admin-about-team .body .data .role {
  width: 15%;
  color: var(--dark-85);
}

.admin-common .admin-about-team .body .data .admin-social {
  width: 40%;
  display: flex;
  gap: 15px;
}

.admin-common .admin-about-team .body .data .admin-social img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.admin-common .admin-about-team .body .data .edit-icon {
  width: 22%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 40px;
}

.admin-common .admin-about-team .body .data .edit-icon .edit {
  cursor: pointer;
}

.common-popup .about-add-team-member form .about-add-team-member-selected-image {
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.common-popup .about-add-team-member form .about-add-team-member-selected-image .image {
  height: 100%;
  width: 220px;
  object-fit: cover;
}

















/* ------------ Admin Projects Section ------------ */
.admin-projects .admin-projects-heading .heading .actions .plus {
  cursor: pointer;
  transform: rotate(45deg);
}

.admin-projects .admin-projects-heading .body {
  gap: 5px !important;
}

.admin-projects .admin-projects-heading .body .data {
  border: 1px solid var(--dark-50);
  padding: 10px;
  border-radius: 5px;
  align-items: center;
  transition: 0.5s;
  cursor: pointer;
}

.admin-projects .admin-projects-heading .body .data:hover {
  background: var(--primary-5);
  border: 1px solid var(--primary-100);
}

.admin-projects .admin-projects-heading .body .data .count {
  width: 5%;
  color: var(--dark-85);
  font-weight: 600;
}

.admin-projects .admin-projects-heading .body .data .project-img {
  width: 15%;
  height: 68px;
}

.admin-projects .admin-projects-heading .body .data .project-img img {
  width: 120px;
  height: 100% !important;
  object-fit: cover;
  border-radius: 4px;
}

.admin-projects .admin-projects-heading .body .data .project-name {
  width: 25%;
  color: var(--dark-85);
  font-weight: 600;
}

.admin-projects .admin-projects-heading .body .data .project-type {
  width: 30%;
  color: var(--dark-85);
}

.admin-projects .admin-projects-heading .body .data .edit-delete-icon {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 40px;
}

.admin-projects .admin-projects-heading .body .data .edit-delete-icon img {
  cursor: pointer;
  width: 25px;
}















/* ------------ Admin Projects Section/add project ------------ */
.common-popup .add-new-project .add-project-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.common-popup .add-new-project .add-project-heading h4 {
  color: var(--primary-100);
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
}

.common-popup .add-new-project .add-project-heading .cancel-icon {
  color: var(--dark-85);
  font-size: 1.3rem;
  cursor: pointer;
  width: 25px;
  height: 25px !important;
}

.common-popup .add-new-project form {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  gap: 20px;
}

.common-popup .add-new-project form input {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid var(--dark-50);
  background: transparent;
  font-size: 1rem;
  font-weight: 400;
  color: var(--dark-100);
  border-radius: 3px;
  outline: none;
}

.common-popup .add-new-project form input#projectImages {
  display: none;
}

.common-popup .add-new-project form .image-label {
  width: 100% !important;
  border: 1px solid var(--dark-50);
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}

.common-popup .add-new-project form .image-label p {
  color: var(--dark-70);
  font-size: 0.9rem;
  margin-bottom: 10px !important;
}

.common-popup .add-new-project form .image-label .image-icon {
  font-size: 1.3rem;
  color: var(--primary-100);
}

.common-popup .add-new-project form input:focus {
  border: 1px solid var(--primary-100);
}

.common-popup .add-new-project form .add-project-button {
  padding: 10px 15px;
  border: 1px solid var(--dark-50);
  background: var(--primary-100);
  color: var(--white);
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 3px;
}

.common-popup form .all-selected-images {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
  width: 100%;
  position: relative;
}

.common-popup form .all-selected-images .single-image {
  position: relative;
}

.common-popup form .all-selected-images .selected-img {
  height: 80px;
  width: 80px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid var(--dark-5);
  position: relative;
}

.common-popup form .all-selected-images .remove-img {
  position: absolute;
  right: 5px;
  top: -5px;
  padding: 5px;
  background: white;
  color: var(--primary-100);
  z-index: 4;
  font-size: 1.5rem;
  border-radius: 50%;
}











/* ------------ Admin Projects Section/edit project ------------ */
.common-popup form .input-field.edit-project-prev-images {
  padding: 25px 15px 8px 15px;
  border: 1px solid var(--dark-50);
  border-radius: 3px;
}












/* ------------ Admin Contact Section ------------ */

.admin-contact .admin-contact-heading .heading .actions .mark-read {
  font-size: 14px;
  font-weight: 500;
  color: var(--primary-100);
  cursor: pointer;
}

.admin-contact .admin-contact-heading .heading .actions .all-viewed {
  font-size: 14px;
  font-weight: 500;
  color: var(--dark-50);
}

.admin-contact .body {
  gap: 5px !important;
}

.admin-contact .body .data {
  gap: 15px;
  border: 1px solid var(--dark-50);
  padding: 8px 10px;
  border-radius: 4px;
  transition: 0.5s;
}

.admin-contact .body .data.marked-read {
  background: var(--gray);
}

.admin-contact .body .data .contact-mail-data {
  display: flex;
  width: 100%;
}

.admin-contact .body .data:hover {
  background: var(--primary-5);
  border: 1px solid var(--primary-100);
  cursor: pointer;
}

.admin-contact .body .data .content-count {
  width: 50px;
}

.admin-contact .body .data .content-title {
  width: 25% !important;
}

.admin-contact .body .data .content-data {
  width: 65% !important;
  font-size: 14px !important;
}

.admin-contact .body .data .mark-read-action-delete {
  width: 5%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 35px;
}

.admin-contact .body .data .mark-read-action-delete .mark-read-show-icon {
  display: flex;
  align-items: center;
}

.admin-contact .body .data .mark-read-action-delete img {
  cursor: pointer;
  width: 25px;
  position: relative;
  z-index: 3;
}

.admin-contact .body .data img.delete-icon {
  margin-left: 0px !important;
}

.view-mail-popup-bg {
  position: fixed;
  height: 100vh;
  width: 100%;
  background: var(--black-20);
  backdrop-filter: blur(3px);
  left: 0;
  top: 0;
  z-index: 10;
  transform: scale(0);
  transition: 0.5s;
}

.view-mail-popup-bg.open {
  transform: scale(1);
}

.view-mail-popup {
  position: fixed;
  max-height: calc(100vh - 200px);
  height: auto;
  width: min(1200px, 90%);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: var(--bg-primary);
  padding: 25px 30px;
  border-radius: 8px;
  border: 1px solid var(--primary-10);
  overflow-y: scroll;
}

.view-mail-popup .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
}

.view-mail-popup .heading .subject {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--dark-85);
  display: flex;
  align-items: center;
}

.view-mail-popup .heading .subject img.tick {
  cursor: pointer;
  margin-left: 20px;
}

.view-mail-popup .heading .read-delete-action {
  display: flex;
  gap: 20px;
  align-items: center;
}

.view-mail-popup .heading .read-delete-action .mark-read {
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--dark-70);
  cursor: pointer;
}

.view-mail-popup .heading .read-delete-action .delete {
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--danger);
  cursor: pointer;
}

.view-mail-popup .email-account {
  margin-bottom: 25px;
}

.view-mail-popup .email-account .email {
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--dark-50);
}

.view-mail-popup .email-account .email span {
  color: var(--dark-100);
}

.view-mail-popup .mail-body {
  font-size: 1rem;
  color: var(--dark-85);
  line-height: 20px;
  white-space: pre-line;
}
















/* ------------ Admin Footer Section ------------ */
.admin-footer-mail {
  margin-top: 20px;
}

.admin-footer-mail .admin-footer-email .mark-copy {
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--dark-85);
  cursor: pointer;
}

.admin-footer-mail .admin-footer-email .copied {
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--success);
}

.admin-footer-mail .admin-footer-email .body {
  gap: 5px !important;
}

.admin-footer-mail .admin-footer-email .body .email {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--dark-50);
  border-radius: 5px;
  padding: 10px;
}

.admin-footer-mail .admin-footer-email .body .email .mail-data {
  display: flex;
  align-items: center;
  gap: 40px;
}

.admin-footer-mail .admin-footer-email .body .email .mail-data .content-count,
.admin-footer-mail .admin-footer-email .body .email .mail-data .content-title {
  font-weight: 500;
  color: var(--dark-85);
}

.admin-footer-mail .admin-footer-email .body .email .copy-icon {
  cursor: pointer;
}


















/* ----------------------------- Responsive Design ----------------------------- */

@media only screen and (max-width: 1350px) {

  /* go to top */
  .go-to-top {
    right: 20px;
    bottom: 30px;
  }

  .go-to-top .to-back-text {
    display: none;
  }

  .go-to-top .arrow-up {
    display: block;
  }

  /* about */
  .our-team .team-info .team-data .team-member {
    height: 510px;
  }

  .our-team .team-info .team-data .team-member .others-info {
    padding: 30px;
  }
}

@media only screen and (max-width: 1100px) {

  /* home */
  .achievements {
    gap: 10%;
  }

  .work-process .process-steps {
    flex-direction: column;
    gap: 45px;
  }

  .got-a-project {
    padding: 70px;
  }

  .got-a-project .title {
    font-size: 3.5rem;
    line-height: 70px;
  }

  /* about */
  .we-value {
    padding: 0px 0 50px 0;
  }

  .we-value section h2.section-title {
    margin-top: 100px !important;
    margin-bottom: 50px !important;
    width: 100%;
    text-align: left;
    left: 0;
    transform: translate(0%);
    top: 0px;
  }

  .we-value section .points .row.row-one .point:nth-child(2),
  .we-value section .points .row.row-two .point:nth-child(2) {
    text-align: start;
  }

  .we-value section .points .row .point.center {
    text-align: left;
  }

  .we-value section .points .row.row-two {
    padding: 0px;
  }

  .we-value section .points .row.row-three {
    justify-content: flex-start;
    margin-bottom: 0px;
  }

  .we-value section .points .point {
    width: calc(50% - 20px);
    position: relative;
    padding-left: 25px;
  }

  .we-value section .points .point::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 1px;
    background: var(--dark-50);
  }

  .our-team .team-info .team-data .team-member {
    height: 410px;
  }

  .our-team .team-info .team-data .team-member .others-info {
    padding: 20px;
  }

  .start-project {
    padding: 60px;
    gap: 40px;
  }

  .start-project .title {
    font-size: 3.5rem;
    line-height: 70px;
  }

  .start-project a .action-img {
    height: 120px;
    width: 120px;
  }


  /* project */
  .all-projects .project-data {
    grid-template-columns: repeat(1, 1fr);
  }

  .project-info .project-name {
    padding: 120px 40px;
  }

  .project-info .project-name h1 {
    font-size: 96px;
    line-height: 96px;
  }

  .project-info .thumbnail-othersInfo {
    flex-direction: column;
  }

  .project-info .thumbnail-othersInfo .thumbnail {
    width: 100%;
  }

  .project-info .thumbnail-othersInfo .others-info {
    width: 100%;
    gap: 25px;
  }

  .detailed-info .short-description {
    font-size: 40px;
  }

}

@media only screen and (max-width: 1000px) {
  .navigation .items {
    display: none;
  }

  .navigation .cta {
    display: none;
  }

  .navigation .hamburger-icon {
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 50px;
    width: 50px;
    justify-content: center;
    align-items: center;
    margin: 13px 0px;
    position: relative;
    z-index: 20;
  }

  .navigation .hamburger-icon .line-one,
  .navigation .hamburger-icon .line-two {
    height: 1px;
    width: 45px;
    background: var(--dark-85);
    transition: 0.5s;
  }

  .navigation .hamburger-icon.open {
    gap: 0px;
  }

  .navigation .hamburger-icon .line-two {
    position: relative;
    top: -1px;
  }

  .mobile-items {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 0;
    width: 100%;
    background: var(--bg-primary);
    left: 0;
    top: 0px;
    z-index: -1;
    gap: 10px;
    transition: 0.5s;
  }

  .mobile-items a {
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--dark-70);
    transition: 0.5s;
    position: relative;
    display: none;
    transition: 0.5s;
    transition-delay: 0.3s;
  }

  .mobile-items.open {
    height: 100vh;
  }

  .mobile-items.open a {
    display: block;
  }

  /* home */
  .hero-section .top-items .heading {
    font-size: 2.3rem;
    line-height: 50px;
  }

  .achievements {
    flex-wrap: wrap;
    gap: 20px;
  }

  .achievements .achievement {
    width: 100%;
    padding: 20px;
  }

  .achievements .achievement:nth-child(1) {
    background: #f5eef8;
  }

  .achievements .achievement:nth-child(2) {
    background: #ebf5fb;
    position: relative;
  }

  .achievements .achievement:nth-child(2)::before,
  .achievements .achievement:nth-child(2)::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 2px;
    background: var(--white);
    top: 0;
  }

  .achievements .achievement:nth-child(2)::before {
    left: 0;
  }

  .achievements .achievement:nth-child(2)::after {
    right: 0;
  }

  .achievements .achievement:nth-child(3) {
    background: #fef9e7;
  }

  .faqs {
    padding: 70px 0;
  }

  .faqs .heading {
    font-size: 3rem;
  }

  .choose-us .content {
    gap: 30px;
  }

  .client-says .title {
    font-size: 3rem;
  }

  .got-a-project {
    padding: 60px;
    gap: 40px;
  }

  .got-a-project .title {
    font-size: 3rem;
    line-height: 60px;
  }

  .got-a-project a .action-img {
    height: 120px;
    width: 120px;
  }

  /* about */
  .about-heading {
    padding: 60px 0 100px 0;
  }

  .about-heading .title {
    font-size: 3rem;
    line-height: 60px;
  }

  .heading-line-svg {
    top: 200px;
    width: 500px;
  }

  .why-different {
    padding: 70px 0;
  }

  .why-different section {
    gap: 60px;
    flex-direction: column;
  }

  .why-different section h2.section-title {
    margin-top: 0px !important;
    width: 100%;
  }

  .why-different section .points {
    width: 100%;
    gap: 60px;
  }

  .why-different section .points .point {
    /* position: relative; */
    padding-left: 30px;
    height: fit-content;
  }

  .why-different section .points .point::before {
    left: 0px;
  }

  .we-value {
    padding: 40px 0 50px 0;
    position: relative;
  }

  .we-value section h2.section-title {
    margin-top: 50px !important;
    margin-bottom: 60px !important;
  }

  .we-value section .points .point {
    padding-left: 30px;
  }

  .our-team {
    padding: 60px 0;
  }

  .our-team .team-info .team-data {
    display: none;
  }

  .team-data-m {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(210px, 3fr));
    grid-gap: 20px;
  }

  .team-data-m .team-member-m {
    width: 100%;
    /* background: linear-gradient(0.5turn, var(--primary-5), var(--primary-10)); */
    padding: 10px;
    border-radius: 5px;
    border: 2px solid var(--white);
  }

  .team-data-m .team-member-m:nth-child(5n + 1) {
    background: #f5eef8;
  }

  .team-data-m .team-member-m:nth-child(5n + 2) {
    background: #ebf5fb;
  }

  .team-data-m .team-member-m:nth-child(5n + 3) {
    background: #e8f8f5;
  }

  .team-data-m .team-member-m:nth-child(5n + 4) {
    background: #fef9e7;
  }

  .team-data-m .team-member-m:nth-child(5n + 5) {
    background: #fbeee6;
  }

  .team-data-m .team-member-m img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 3px;
  }

  .team-data-m .team-member-m .others-info-m .name-m {
    font-size: 20px;
    color: var(--dark-85);
    font-weight: 500;
    margin-bottom: 1px !important;
  }

  .team-data-m .team-member-m .others-info-m .role-m {
    font-size: 12px;
    color: var(--dark-85);
    font-weight: 300;
    margin-bottom: 0px !important;
  }

  .team-data-m .team-member-m .others-info-m .social-m {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 10px;
  }

  .team-data-m .team-member-m .others-info-m .social-m a img {
    height: 25px;
    width: 25px;
    border-radius: 50%;
  }

  .start-project .title {
    font-size: 3rem;
    line-height: 60px;
  }

  /* contact */
  .contact .possible-ways {
    justify-content: center;
    gap: 100px;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
  }

  /* footer */
  .footer .circles {
    height: 400px;
  }

  .footer .email-links {
    flex-direction: column;
  }

  .footer .email-links .enter-email {
    width: 100%;
  }

  .footer .email-links .links {
    width: 100%;
  }

  .footer .email-links .enter-email form {
    width: 60%;
  }
}

@media only screen and (max-width: 900px) {

  /* home */
  .hero-section .top-items .shape-one {
    display: none;
  }

  .hero-section .top-items .shape-two {
    display: none;
  }

  .service-provide {
    padding: 70px 0;
  }

  .service-provide .circles {
    width: 350px;
  }

  .service-provide .heading {
    font-size: 3rem;
    line-height: 55px;
  }

  .service-provide .contents .service {
    padding: 45px 0 1px 0;
  }

  .service-provide .contents .service .count-name-action {
    margin-bottom: 30px;
  }

  .service-provide .contents .service .count-name-action .count-name .count p {
    font-size: 0.9rem;
  }

  .service-provide .contents .service .count-name-action .count-name .name {
    font-size: 18px;
  }

  .service-provide .contents .service .count-name-action .more-info {
    font-size: 0.9rem;
  }

  .service-provide .contents .service .service-details-image {
    gap: 50px;
  }

  .service-provide .contents .service .service-details-image .description-action .detail {
    font-size: 0.9rem;
  }

  .service-provide .contents .service .service-details-image .description-action .lets-work a {
    font-size: 0.9rem;
  }

  .service-provide .contents .service .service-details-image .description-action .lets-work img {
    height: 40px;
    width: 40px;
  }

  .service-provide .contents .service .service-details-image .image {
    width: 40%;
  }

  .choose-us .content {
    flex-direction: column;
  }

  .choose-us .content .left-content {
    width: 100%;
  }

  .choose-us .content .right-content {
    width: 100%;
  }

  .project-info .project-name h1 {
    font-size: 80px;
    line-height: 80px;
  }

  .detailed-info .short-description {
    font-size: 32px;
  }

}

@media only screen and (max-width: 750px) {
  /* home */

  .achievements .achievement .achievement-number {
    font-size: 3rem;
  }

  .faqs .contents .faq {
    padding: 40px 0 35px 0;
  }

  .faqs .contents .faq .faq-name-info .name {
    font-size: 18px;
  }

  .client-says .title {
    font-size: 2.5rem;
  }

  .got-a-project {
    padding: 40px;
  }

  .got-a-project .title {
    font-size: 2.5rem;
    line-height: 50px;
  }

  /* about */
  .why-different .arrowSvg {
    left: 100px;
    bottom: -70px;
    width: 300px;
  }

  .start-project {
    padding: 40px;
    gap: 20px;
  }

  .start-project .title {
    font-size: 2.3rem;
    line-height: 50px;
  }

  .start-project a .action-img {
    height: 100px;
    width: 100px;
  }

  /* projects */
  .projects-heading {
    padding: 50px 0 30px 0;
  }

  .projects-heading .desc {
    margin-top: 15px !important;
  }

  .heading-line-svg {
    width: 400px;
  }

  .project-info .project-name {
    padding: 90px 30px;
  }

  .project-info .project-name h1 {
    font-size: 65px;
    line-height: 65px;
  }

  .detailed-info .short-description {
    font-size: 28px;
  }

  .detailed-info .check-project .title {
    font-size: 28px;
  }

  .detailed-info .check-project a {
    font-size: 18px;
  }


  /* contact */
  .contact .other-possible-way {
    margin-bottom: 70px;
  }
}

@media only screen and (max-width: 630px) {
  .left-top-bg {
    width: 400px;
    height: 400px;
    left: -200px;
    top: -200px;
  }

  /* custom button */
  .custom-button {
    padding: 10px 25px;
  }

  /* home */
  .achievements {
    margin: 10px 0 70px 0;
  }

  .achievements .counts {
    flex-direction: column;
  }

  .achievements .achievement {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .achievements .achievement:nth-child(2)::before,
  .achievements .achievement:nth-child(2)::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    background: var(--white);
    left: 0;
  }

  .achievements .achievement:nth-child(2)::before {
    top: 0;
  }

  .achievements .achievement:nth-child(2)::after {
    top: 100%;
  }

  .service-provide {
    padding: 50px 0;
  }

  .service-provide .circles {
    width: 300px;
  }

  .service-provide .heading {
    font-size: 2.3rem;
    line-height: 45px;
  }

  .service-provide .contents .service .count-name-action .more-info {
    display: none;
  }

  .service-provide .contents .service .count-name-action .more-info-icon {
    display: block;
  }

  .client-says .title {
    font-size: 2rem;
    margin-bottom: 25px !important;
  }

  .client-says .content .review .review-content .name-info .name-position .name {
    font-size: 18px;
  }

  .client-says .content .review .review-content .name-info .name-position .position {
    font-size: 0.8rem;
  }

  .client-says .content .review .review-content .statement q {
    font-size: 0.9rem;
  }

  .got-a-project .title {
    font-size: 2rem;
    line-height: 40px;
  }

  .got-a-project a .action-img {
    height: 90px;
    width: 90px;
  }

  /* projects */
  .projects-heading .title {
    font-size: 3rem;
    line-height: 55px;
  }

  .projects-heading .desc {
    margin-top: 15px !important;
    font-size: 0.9rem;
  }

  .projects-heading .scroll {
    left: 50px;
  }

  .heading-line-svg {
    top: 300px;
    width: 350px;
  }

  /* contact */
  .contact-form form {
    gap: 50px;
  }

  .contact-form form .name-email {
    flex-direction: column;
    gap: 50px;
  }

  .contact-form form textarea {
    height: 38px !important;
    margin-bottom: -7px !important;
  }

  .contact-form .planeSvg {
    width: 500px;
    left: -250px;
    bottom: -350px;
    z-index: 1;
  }

  /* footer */
  .footer {
    padding: 50px 0 55px 0;
  }

  .footer .circles {
    height: 350px;
  }

  .footer .heading {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .footer .email-links .enter-email form {
    width: 100%;
  }
}

@media only screen and (max-width: 550px) {

  /* home */
  .hero-section .top-items .heading {
    font-size: 2rem;
    line-height: 45px;
  }

  .hero-section .top-items .description {
    font-size: 0.9rem;
  }

  .service-provide .contents .service .service-details-image {
    gap: 20px;
  }

  .faqs .contents .faq .faq-name-info .info .detail {
    font-size: 0.9rem;
    margin-top: 25px !important;
  }

  .got-a-project {
    flex-direction: column;
  }

  .got-a-project .title {
    text-align: center;
  }

  .got-a-project a.icon {
    display: none;
  }

  .got-a-project a.button {
    display: block;
    border: 2px solid var(--white);
    border-radius: 35px;
  }

  .got-a-project a.button .custom-button {
    font-weight: 600 !important;
  }

  /* about */
  .about-heading .title {
    font-size: 2.5rem;
    line-height: 55px;
  }

  .why-different {
    padding: 50px 0;
  }

  .why-different section {
    gap: 30px;
  }

  .why-different section h2.section-title {
    margin-top: 0px !important;
    font-size: 2rem;
  }

  .why-different section .points {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    gap: 35px;
  }

  .why-different .arrowSvg {
    left: 10px;
    bottom: -70px;
    width: 250px;
  }

  .we-value {
    padding: 50px 0 25px 0;
  }

  .we-value section h2.section-title {
    margin-top: 0px !important;
    font-size: 2rem;
    margin-bottom: 30px !important;
  }

  .we-value section .points .row {
    flex-direction: column;
    width: 100%;
    gap: 0px;
  }

  .we-value section .points .point {
    width: 100%;
  }

  .we-value section .points .point {
    margin-bottom: 35px;
  }

  .our-team {
    padding: 50px 0;
  }

  .our-team .team-info .team-heading .title {
    font-size: 2rem;
  }

  .team-data-m .team-member-m img {
    height: 420px;
  }

  .start-project {
    padding: 40px;
    gap: 20px;
  }

  .start-project .title {
    font-size: 2.3rem;
    line-height: 50px;
  }

  .start-project a .action-img {
    height: 100px;
    width: 100px;
  }

  /* projects */
  .heading-line-svg {
    width: 300px;
  }

  .project-info .project-name h1 {
    font-size: 40px;
    line-height: 40px;
}

  /* contact */
  .contact .contact-heading .heading-texts .title {
    margin-top: 0px !important;
    font-size: 2.5rem;
    width: 100%;
    line-height: 55px;
  }

  .contact .contact-heading .heading-texts .desc {
    margin-top: 0px !important;
  }

  .contact .possible-ways {
    justify-content: flex-start;
    gap: 50px;
    flex-wrap: wrap;
  }

  .contact-form .planeSvg {
    width: 400px;
    left: -120px;
    bottom: -380px;
    transform: rotate(-35deg);
  }
}

@media only screen and (max-width: 450px) {

  /* home */
  .service-provide .heading {
    font-size: 2rem;
    line-height: 40px;
    margin: 10px 0 35px 0 !important;
  }

  .service-provide .circles {
    width: 250px;
  }

  .service-provide .contents .service .service-details-image {
    flex-direction: column-reverse;
  }

  .service-provide .contents .service .service-details-image .description-action {
    width: 100%;
  }

  .service-provide .contents .service .service-details-image .description-action .detail {
    margin-bottom: 20px !important;
  }

  .service-provide .contents .service .service-details-image .image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .service-provide .contents .service .service-details-image .image img {
    width: 90%;
  }

  .choose-us .content .right-content img {
    height: 270px;
  }

  /* about */
  .team-data-m .team-member-m img {
    height: 400px;
  }

  .start-project .title {
    font-size: 2rem;
    line-height: 45px;
  }

  .start-project a .action-img {
    height: 80px;
    width: 80px;
  }

  /* projects */
  .heading-line-svg {
    width: 300px;
    display: none;
  }

  /* footer */
  .footer .circles {
    height: 310px;
  }

  .footer .heading .get-started {
    gap: 20px;
    width: 100%;
  }

  .footer .heading .get-started h3 {
    font-size: 18px;
  }

  .footer .email-links .enter-email .email-heading {
    margin-bottom: 35px !important;
  }

  .footer .email-links .links {
    flex-direction: column;
    gap: 25px;
  }

  .footer .email-links .links .column {
    gap: 6px;
  }

  .footer .terms-policy {
    flex-direction: column;
    gap: 10px;
    margin-top: -30px;
  }

  .footer .terms-policy a {
    font-size: 1rem;
    font-weight: 400;
  }
}

@media only screen and (max-width: 400px) {

  /* projects */
  .heading-line-svg {
    width: 100px;
    display: none;
  }
  .detailed-info .short-description{
    font-size: 28px;
    line-height: 38px;
    letter-spacing: 1px;
    word-spacing: 7px;
  }
}

/* Device zoom level */
/* @media screen and (max-width: 1366px) {
    body {
        zoom: 70%;
    }
} */